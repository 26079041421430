<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row justify="center">
        <v-snackbar top
                    v-model="snackbar" :color="color"
                    timeout="2000"
        >
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    X
                </v-btn>
            </template>
        </v-snackbar>
        <v-col cols="12" class="px-10">
            <v-card-title class="text-center">
                <span class="headline">Insert a new note</span>
            </v-card-title>
            <v-card-text>
                <form @submit.prevent="takeAction">
                    <validation-observer
                            ref="observer"
                            v-slot="{ invalid }"
                    >
                        <v-row>
                            <v-col cols="6">
                                <v-switch
                                        v-model="is_paid"
                                        label="Paid"
                                        color="primary"
                                ></v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch
                                        v-model="privacy"
                                        label="Private"
                                        color="primary"
                                ></v-switch>
                            </v-col>
                            <v-col
                                    class="d-flex"
                                    cols="12"
                            >
                                <v-select
                                        :items="course.topics"
                                        item-text='name'
                                        item-value="id"
                                        label="Select topic"
                                        v-model="topic_id"
                                ></v-select>
                            </v-col>

                            <v-col
                                    cols="12"
                            >
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Title"
                                        rules="required"
                                >
                                    <v-text-field
                                            v-model="title"
                                            :error-messages="errors"
                                            label="Note title"
                                            required
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col
                                    cols="12"
                            >
                                <tiptap-vuetify
                                        v-model="body"
                                        :extensions="extensions"
                                        :toolbar-attributes="{ color: 'yellow' }"
                                        placeholder="Enter note"
                                >
                                </tiptap-vuetify>
                            </v-col>

                            <v-col
                                    cols="12"
                                    sm="12">
                                <v-datetime-picker
                                        ref="availableAtRef"
                                        v-model="available_at"
                                        :text-field-props="{prependIcon: 'mdi-calendar'}"
                                        label="Available at"
                                        time-format="hh:mm:ssa"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-col>

                            <v-btn
                                    class="mr-4"
                                    type="submit"
                                    :disabled="invalid"
                                    :loading="loading"
                            >
                                {{ editMode ? 'update' : 'submit' }}
                            </v-btn>

                            <v-btn @click="clear">
                                clear
                            </v-btn>

                        </v-row>
                    </validation-observer>
                </form>
            </v-card-text>
        </v-col>
    </v-row>
</template>
<script>
    import {required} from 'vee-validate/dist/rules'
    import axios from 'axios'
    import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
    import {
        TiptapVuetify,
        Heading,
        Bold,
        Italic,
        Strike,
        Underline,
        Code,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        HorizontalRule,
        History
    } from 'tiptap-vuetify'
    import moment from 'moment'

    export default {
        props: ['course'],
        components: {
            ValidationProvider,
            ValidationObserver,
            TiptapVuetify,
        },
        data: () => ({
            loading: false,
            selectedNote: null,
            editMode: false,
            dialog: false,
            title: '',
            body: '',
            topic_id: '',
            snackbar: false,
            text: '',
            color: 'blue',
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Bold,
                Link,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            is_paid: true,
            privacy: false,
            available_at: '',
        }),
        computed: {
            availableAt() {
                return moment(this.available_at).isValid() ? moment(this.available_at).format() : '';
            },
            form: function () {
                let formData = new FormData();
                if (this.course) {
                    formData.append('course_id',this.course.id);
                }
                if (this.editMode) {
                    formData.append('_method', 'PUT');
                }
                if (this.title) {
                    formData.append('title', this.title);
                }
                if (this.body) {
                    formData.append('body', this.body);
                }
                if (this.topic_id) {
                    formData.append('topic_id', this.topic_id)
                }
                if (this.privacy) {
                    formData.append('privacy', this.privacy ? 'private' : 'public')
                }
                formData.append('is_paid', this.is_paid ? 1 : 0);
                formData.append('available_at', this.availableAt);
                return formData;
            }
        },
        methods: {
            takeAction() {
                this.editMode ? this.update() : this.submit();
            },
            edit(note) {
                this.editMode = true;
                this.title = note.title;
                this.body = note.body;
                this.topic_id = note.topic_id;
                this.link = note.link;
            },
            submit() {
                const url = 'admin/notes';
                this.loading = true;
                axios.post(url, this.form).then(() => {
                    this.loading = false;
                    this.clear();
                    this.color = 'green';
                    this.snackbar = true;
                    this.text = 'New note created';
                    this.loadNotes();
                })
            },
            update() {
                const url = 'admin/notes/' + this.selectedNote.id;
                this.loading = true;
                axios.post(url, this.form).then(() => {
                    this.loading = false;
                    this.clear();
                    this.color = 'green';
                    this.snackbar = true;
                    this.text = 'Note updated';
                    this.loadNotes();
                })
            },
            loadNotes() {
                this.$emit('updated');
            },
            clear() {
                this.title = '';
                this.body = '';
                this.link = '';
                this.topic_id = '';
                this.selectedNote = null;
                this.available_at  = '';
                this.privacy = false;
                this.$refs.availableAtRef.clearHandler();
                this.$refs.observer.reset();
            },
        },
    }

    setInteractionMode('eager');
    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    })
</script>